import { FC } from 'react'
import _ from 'lodash'
import { Text, Flex, VStack, Box, IconButton } from '@chakra-ui/react'
// import { Text, Flex, VStack, Box, IconButton, useToast } from '@chakra-ui/react'
import { FileT } from 'shared/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileText,
  faFileVideo,
  faFileWord,
  faCloudDownloadAlt,
  faArrowUpRightFromSquare
} from '@fortawesome/pro-light-svg-icons'
// import { downloadFile } from 'controllers/storage'
import moment from 'moment'
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons'
import { saveAs } from 'file-saver'

type Props = {
  files: FileT[]
  projectEditTime: number
  filename: string
  blob?: Blob
}

const BidPackageFiles: FC<Props> = ({ files, projectEditTime, filename, blob }) => {
  // const toast = useToast()

  const icons = {
    image: faFileImage,
    audio: faFileAudio,
    video: faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileText,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive
  }

  const handleDownload = async () => {
    if (blob) {
      saveAs(blob, filename)
    }
  }

  const renderDownloadButton = () => {
    return (
      <IconButton
        position={'absolute'}
        top='0px'
        right='20px'
        size='xs'
        aria-label='Download file'
        variant='unstyled'
        icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}
        onClick={handleDownload}
      />
    )
  }

  const openScopeLetter = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  }

  const renderNavigateButton = () => {
    return (
      <IconButton
        position={'absolute'}
        top='0px'
        right='0px'
        size='xs'
        aria-label='Open file'
        variant='unstyled'
        icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        onClick={openScopeLetter}
      />
    )
  }

  const renderFile = (f: FileT) => {
    let icon = faFile
    if (f.contentType) {
      const t = _.get(_.split(f.contentType, '/'), 0)
      icon = _.get(icons, t, _.get(icons, f.contentType, faFile))
    }

    const fileExt = f.name.slice((Math.max(0, f.name.lastIndexOf('.')) || Infinity) + 1)
    const name = f.name.substring(0, f.name.length - fileExt.length - 1)
    return (
      <Flex
        key={f.id}
        borderWidth='1px'
        borderRadius={'base'}
        w={28}
        h={28}
        direction='column'
        align={'center'}
        pt={4}
        bg='white'
        position={'relative'}
        as='button'
        onClick={() => window.open(f.url, '_blank')}
        _hover={{ bg: 'green.50', borderColor: 'green.500' }}
      >
        <Box color='red.400'>
          <FontAwesomeIcon icon={icon} size='2x' />
        </Box>
        <Text fontSize={'8px'} color='gray.500'>
          .{fileExt}
        </Text>
        <Flex justify={'center'} maxH={'24px'} overflowY='hidden' mt={1.5}>
          <Text
            maxWidth={24}
            textAlign={'center'}
            lineHeight='1.2em'
            fontSize={'10px'}
            fontWeight='semibold'
            textOverflow={'ellipsis'}
            letterSpacing='-0.5px'
          >
            {name}
          </Text>
        </Flex>
        <Flex mt={0.5}>
          <Text color={'gray.500'} fontSize={'8px'}>
            modified {moment(f.createdAt).format('ll')}
          </Text>
        </Flex>
        {/* {renderDownloadButton(f)}
        {renderNavigateButton(f)} */}
      </Flex>
    )
  }

  const scopeButton = (
    <Flex
      key={'scope_button'}
      borderWidth='2px'
      borderColor={'green.500'}
      borderRadius={'base'}
      w={28}
      h={28}
      direction='column'
      align={'center'}
      pt={4}
      bg='white'
      position={'relative'}
    >
      <Box color='green.500'>
        <FontAwesomeIcon icon={faFileCheck} size='2x' />
      </Box>
      <Text fontSize={'8px'} color='gray.500'>
        .pdf
      </Text>
      <Flex justify={'center'} maxH={'24px'} overflowY='hidden' mt={1.5}>
        <Text
          maxWidth={24}
          textAlign={'center'}
          lineHeight='1.2em'
          fontSize={'10px'}
          fontWeight='semibold'
          textOverflow={'ellipsis'}
          letterSpacing='-0.5px'
        >
          Scope Letter
        </Text>
      </Flex>
      <Flex mt={0.5}>
        <Text color={'gray.500'} fontSize={'8px'}>
          modified {moment(projectEditTime).format('ll')}
        </Text>
      </Flex>
      {renderDownloadButton()}
      {renderNavigateButton()}
    </Flex>
  )

  // if (_.isEmpty(files)) {
  //   return (
  //     <Flex w='full' justify={'flex-start'} pt={4} px={2}>
  //       <Text fontSize='sm' color='gray.500' fontStyle={'italic'} fontWeight='light'>
  //         No files in bid package
  //       </Text>
  //     </Flex>
  //   )
  // } else {
  return (
    <Box flex={1} py={4} overflowY='auto'>
      <VStack spacing={4}>
        {scopeButton}
        {_.map(files, renderFile)}
      </VStack>
    </Box>
  )
  // }
}

export default BidPackageFiles
